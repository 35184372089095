<template>
  <el-row :gutter="15">
<!--    <el-col :span="12" class="mb-15">-->
<!--      <formList-->
<!--          formSize="small"-->
<!--          :formLabel="searchFormLabel"-->
<!--          :form="searchParams"-->
<!--          formLabelWidth="0"-->
<!--      ></formList>-->
<!--    </el-col>-->
<!--    <el-col :span="2">-->
<!--      <el-button size="small" type="primary" @click="searchList" :loading="loading.listLoading">搜索</el-button>-->
<!--    </el-col>-->
<!--    <el-col :span="8" class="ta-r">-->
<!--      <el-button size="small" type="success"-->
<!--                 v-if="!loading.listLoading && invoiceList.length === 0"-->
<!--                 @click="goPage('carInfo')">去认证车辆</el-button>-->
<!--      <span v-if="invoiceList.length !== 0">-->
<!--        <el-checkbox v-model="checkAll" @change="handleCheckAll">全选</el-checkbox>-->
<!--      </span>-->
<!--      <el-button size="small" type="warning"-->
<!--                 :disabled="invoiceList.length === 0"-->
<!--                 @click="goTicket">去开票</el-button>-->
<!--    </el-col>-->
    <el-col :span="24" v-loading="loading.listLoading"></el-col>
    <el-col :span="24" v-if="invoiceList.length === 0 && !loading.listLoading">
      <el-empty class="py-80" description="暂无数据">
        <el-button v-if="!isLogin" size="small" type="primary" @click="cacheFromInfo">去登录</el-button>
      </el-empty>
    </el-col>
    <el-col
        v-else
        :span="6"
        class="mb-20"
        v-for="inv in invoiceList">
      <el-card class="box-card" :class="{bgChecked: inv.flag}">
        <div slot="header" class="clearfix" style="line-height: 28px">
          <el-checkbox
              v-model="inv.flag"
              :label="inv.mvLicense"
              @change="checkInv(inv)"></el-checkbox>
          <div class="fr tc-link fs-24 posAB">￥{{ inv.totalToll>0?inv.totalToll/100:inv.totalToll }}</div>
        </div>
        <ul>
          <li>
            <i class="tc-link mr-5 el-icon-time"></i>
            过渡时间：{{ inv.checkTime }}
          </li>
          <li>
            <i class="tc-link mr-5 el-icon-map-location"></i>
            汽渡名称：{{ inv.spare2 }}
          </li>
          <li>
            <i class="tc-link mr-5 el-icon-wallet"></i>
            支付方式：{{ ['现金','移动支付','移动支付','ETC','预售票','预售票','白名单免费','','','','','储值卡'][inv.payMethod] }}
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import interfaceWechat from "@/mixins/interfaceWechat";
import publicFun from "@/mixins/public";
import formList from "@/components/formList";

export default {
  name: "notInvoiceList",
  mixins: [interfaceWechat, publicFun],
  components: {
    formList
  },
  data() {
    return {
      isLogin: sessionStorage.userinfo,
      searchFormLabel: [
        {
          colSpan: 10,
          label: '',
          type: 'text',
          key: 'mvLicense',
          placeholder: '车牌号'
        },
        {
          colSpan: 14,
          label: '',
          type: 'datetimerangePicker',
          typeName: 'datetimerange',
          key: 'datetime',
          startPlaceholder: '开始时间',
          endPlaceholder: '结束时间',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          width: '100%'
        },
      ],
      searchParams: {
        unionid: '',
        invoiceStatus: '0',
        mvLicense: '',
        datetime: [],
        datetimebegin: '',
        datetimeend: ''
      },
      loading: {
        listLoading: false
      },
      invoiceList: [],
      checkedList: [],
      checkedIdList: [],
      checkAll: false
    }
  },
  mounted() {
    this.searchParams.unionid = sessionStorage.userinfo ? JSON.parse(sessionStorage.userinfo).unionid : '';
    if (this.searchParams.unionid) this.getList();
  },
  methods: {
    searchList(params) {
      this.searchParams.datetimebegin = params.datetime ? params.datetime[0] : '';
      this.searchParams.datetimeend = params.datetime ? params.datetime[1] : '';
      this.searchParams.mvLicense = params.mvLicense;
      this.checkAll = false;
      this.clearCheck();
      this.invoiceList = [];
      this.getList();
    },
    getList() {
      let params = JSON.parse(JSON.stringify(this.searchParams));
      params.mvLicense = [this.searchParams.mvLicense];
      this.getInfo('/journey/list', params, 'invoiceList');
    },
    clearCheck() {
      this.checkedList = [];
      this.checkedIdList = [];
    },
    goTicket() {
      if (this.checkedList.length === 0) return this.$message.info('请勾选需要开票的数据。')
      this.$router.push({
        name: 'invoice',
        params: {
          type: 'invoicePage',
          item: 'carInfo'
        }
      });
      sessionStorage.carInfo = JSON.stringify(this.checkedList);
    },
    handleCheckAll() {
      this.clearCheck();
      this.invoiceList.forEach(item => {
        item.flag = this.checkAll;
        if (item.flag) {
          this.checkedList.push({
            id: item.id,
            sdate: item.sdate,
            mvLicense: item.mvLicense,
            exitTime: item.exitTime,
            stationId: item.stationId,
            totalToll: item.totalToll,
            checkTime: item.checkTime,
            email: item.email,
            phonenum: item.phonenum
          });
          this.checkedIdList.push(item.id);
        } else {
          this.clearCheck();
        }
      });
      this.invoiceList.splice(); // 更新视图：解决多选框数据改变，视图不变问题
    },
    checkInv(item) {
      if (item.flag) {
        this.checkedList.push({
          id: item.id,
          sdate: item.sdate,
          mvLicense: item.mvLicense,
          exitTime: item.exitTime,
          stationId: item.stationId,
          totalToll: item.totalToll,
          checkTime: item.checkTime,
          email: item.email,
          phonenum: item.phonenum
        });
        this.checkedIdList.push(item.id);
      } else {
        this.checkAll = false;
        let index = this.checkedIdList.findIndex(id => {return id === item.id})
        this.checkedList.splice(index, 1);
        this.checkedIdList.splice(index, 1);
      }
      this.invoiceList.splice(); // 更新视图：解决多选框数据改变，视图不变问题
      this.checkAll = this.checkedList.length === this.invoiceList.length;
      this.$parent.checkAll = this.checkAll;
      this.getTotal();
    },
    getTotal() {
      if (this.checkedList.length===0) {
        this.$parent.totalMoney = 0;
        return;
      }
      let total = 0;
      this.checkedList.forEach(i => {
        total += i.totalToll;
      });
      this.$parent.totalMoney = total>0 ? total/100 : total;
    }
  }
}
</script>

<style scoped lang="scss">
ul {
  position: relative;
  padding: 0;
  li {
    padding: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.posAB {
      position: absolute;
      right: 5px;
      top: 0;
    }
  }
}
.el-card {
  ::v-deep {
    .el-card__header {
      padding: 12px 20px;
    }
  }
}
.el-checkbox {
  margin-right: 10px !important;
  ::v-deep {
    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      &::after {
        left: 5px;
        width: 4px;
        height: 10px;
      }
    }
  }
}
.bgChecked {
  background: #f6f7ff;
}
.checkAll_box {
  padding: 10px 0;
  margin-bottom: 15px;
}
</style>